<template>
	<div class="animated fadeIn">
		<b-card class="card-border mt-4">
			<b-card-title><i class="fa fa-clipboard"></i> Company Importer</b-card-title>
			<b-card-sub-title>Imports company data in bulk</b-card-sub-title>
			<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

			<div fluid class="px-2 mt-4">
				<b-form @submit.stop.prevent="saveOnDatabase" novalidate>
					<div v-show="!importOngoing && !importDone">
						<!-- Company Form -->
						<b-card title="Upload Form">
							<b-row class="mt-2 mb-4 ml-1" no-gutters>
								<b-col sm="10" md="8" lg="6" class="mr-4">
									<b-form-group label="Company Form"
										description="Please select a valid json or csv file for company import form format.">
										<b-form-file v-model="file" ref="fileinput" @change="onUploadForm($event)"
											:state="Boolean(file)" placeholder="Choose a JSON or CSV file"></b-form-file>
									</b-form-group>
									<div>
										Download JSON template
										<a :href="jsonTemplateUrl" download="Company Importer.json">
											here
										</a>
										and CSV template
										<a :href="csvTemplateUrl" download="Company Importer.csv">
											here.
										</a>
									</div>
								</b-col>
								<b-col sm="1">
									<b-button variant="primary" class="reset-button"
										@click="startAnotherImport()">Reset</b-button>
								</b-col>
							</b-row>
						</b-card>

						<!-- Content Preview -->
						<b-card v-if="!importOngoing && jsonData !== null" title="Content Preview"
							sub-title="Below is the overview of the content of the company import form you have selected">
							<br />
							<json-viewer :value="jsonData" />
							<br />
							<b-button variant="primary" @click="onImportData()">Import</b-button>
						</b-card>
					</div>

					<div v-show="importOngoing || importDone">
						<b-row class="my-12">
							<b-col sm="12">
								<b-card title="Import Status" sub-title>
									<div v-if="importErrors.length > 0">
										<p class="card-text">{{ importResultLog }}</p>
										<ul>
											<li v-for="(item, index) in importErrors" :key="index">
												{{ item }}
											</li>
										</ul>
									</div>

									<div v-else>
										<p class="card-text my-4">
											{{ importStatusDisplay }}
										</p>
									</div>

									<span v-show="!importOngoing">
										<b-button variant="primary" @click="startAnotherImport()">
											Start Another Import
										</b-button>
									</span>
								</b-card>
							</b-col>
						</b-row>
					</div>
				</b-form>
			</div>
		</b-card>
	</div>
</template>

<script>
// Utils
import { DateUtil } from '@/utils/dateutil';
import { ImportUtil } from '@/utils/importUtil';

// API
import dataImporterApi from '@/api/dataImporterApi';

// Others
import config from '@/config/env-constants';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
let Papa = require('papaparse');
import _ from 'lodash';

export default {
	name: 'company-importer',
	components: { Loading },
	data() {
		return {
			file: null,
			jsonData: null,

			importOngoing: false,
			importDone: false,
			hasError: false,
			importResultLog: '',
			importErrors: [],

			jsonTemplateUrl: '../../assets/files/CompanyImporter.json',
			csvTemplateUrl: '../../assets/files/CompanyImporter.csv',

			isSuperAdmin: this.$store.getters.isSuperAdmin,
			loggedUserCompany: this.$store.getters.loggedUserCompany,
			currUserId: this.$store.getters.loggedUser.id,
			isLoading: false,
		};
	},
	computed: {
		importStatusDisplay() {
			let statusDisplay = '';

			if (this.importOngoing) {
				statusDisplay = 'Import In-Progress.';
			} else if (this.hasError) {
				statusDisplay = this.importResultLog;
			} else {
				statusDisplay = 'Import Successful! \n' + this.importResultLog;
			}
			return statusDisplay;
		},
		companyNameRegex() {
			return config.companyNameRegex;
		},
		remarksRegex() {
			return config.remarksRegex;
		},
		addressRegex() {
			return config.addressRegex;
		},
		fullNameRegex() {
			return config.fullNameRegex;
		},
		contactNoRegex() {
			return config.contactNoRegex;
		},
		brandRegex() {
			return config.brandRegex;
		},
		branchCodeRegex() {
			return config.branchCodeRegex
		},
		emailRegex() {
			return config.emailRegex;
		},
	},
	mounted() {
		setTimeout(() => {
			// Filter Access
			if (this.$store.getters.isViewer || this.$store.getters.isScanner) {
				this.$router.push('/dashboard');
				this.$toaster.warning('You are not allowed to access this page.');
			}
		}, config.timeout);
	},
	methods: {
		startAnotherImport() {
			this.importDone = false;
			this.importOngoing = false;

			// reset error status
			this.resetErrorStatus();

			// reset form fields
			this.$refs.fileinput.reset();
			this.file = null;
			this.jsonData = null;
		},
		resetErrorStatus() {
			this.hasError = false;
			this.importResultLog = '';
			this.importErrors = [];
		},

		onUploadForm(ev) {
			const file = ev.target.files[0];

			if (this.validateUploadForm(file)) {
				const reader = new FileReader();
				reader.onload = (e) => {
					let extension = file.name.split('.').pop().toLowerCase();
					if (extension === 'csv') {
						const { data } = Papa.parse(e.target.result, {
							header: true,
							skipEmptyLines: true,
							dynamicTyping: true,
							encoding: "UTF-8",
						});

						this.jsonData = this.formatJsonData(data, extension);
					} else {
						this.jsonData = JSON.parse(e.target.result);
						this.jsonData = this.formatJsonData(this.jsonData.companies, extension);
					}
				};

				reader.readAsText(file);
			}
		},
		validateUploadForm(file) {
			let isValid = true;
			const fileTypes = ['csv', 'json'];
			const extension = file.name.split('.').pop().toLowerCase();
			const isAllowed = fileTypes.indexOf(extension) > -1;

			if (!file) {
				this.$toaster.warning('Please select a valid company form to proceed.');
				isValid = false;
				this.jsonData = null;
			} else if (!isAllowed) {
				this.$toaster.error('Invalid File Type: Please import a valid company form in JSON or CSV file format.');
				isValid = false;
				this.jsonData = null;
			}

			return isValid;
		},
		formatJsonData(params, extension) {
			let results = [];
			params.forEach((param) => {
				param = ImportUtil.trimWhiteSpaces(param);

				if (extension === 'csv') {
					param.geoaddress = {
						latitude: 0,
						longitude: 0,
					};

					if (param.latitude) {
						if (ImportUtil.isValidNumber(param.latitude)) {
							param.geoaddress.latitude = parseFloat(param.latitude);
						} else {
							param.geoaddress.latitude = param.latitude;
						}
						delete param.latitude;
					}
					if (param.longitude) {
						if (ImportUtil.isValidNumber(param.longitude)) {
							param.geoaddress.longitude = parseFloat(param.longitude);
						} else {
							param.geoaddress.longitude = param.longitude;
						}
						delete param.longitude;
					}
				}

				param.hasParentCompany = param.hasParentCompany && /^true$/i.test(param.hasParentCompany) ? true : false;

				results.push(param);
			});

			return {
				companies: results,
			};
		},

		async onImportData() {
			try {
				let companiesArr = this.jsonData.companies;
				if (companiesArr.length === 0) {
					this.$toaster.warning('At least 1 company is required per import.');
					return;
				} else if (companiesArr.length > 5000) {
					this.$toaster.warning(
						'Only maximum of 5,000 companies is allowed per import.'
					);
					return;
				} else if (ImportUtil.hasDuplicates(companiesArr, 'name')) {
					let duplicates = ImportUtil.getDuplicates(companiesArr, 'name');
					this.$toaster.warning(
						'Duplicates detected! Please address the following companies: ' +
						JSON.stringify(duplicates)
					);
					return;
				} else if (ImportUtil.hasBlankColumnName(companiesArr[0])) {
					this.$toaster.warning('Empty Column Name is not allowed. Please check your column names.');
					return;
				} else if (!this.validateDataImport(companiesArr)) {
					return;
				}

				await this.saveOnDatabase(this.jsonData);
			} catch (error) {
				this.$toaster.warning('The imported company data is invalid and cannot be processed');
			}
		},
		validateDataImport(companies) {
			let isValid = true;
			let errors = [];

			let companyTypes = ImportUtil.getTypes(config.companyTypes);

			_.forEach(companies, (item, key) => {
				let companyName = item.name;
				let index = key + 1;
				let errPrefix = (!companyName || companyName.length === 0) ? 'Item ' + index : 'Company ' + companyName;

				// Company Name
				if (!item.name || item.name.length === 0) {
					errors.push(errPrefix + ' has no name. This is required.');
				} else if (!this.companyNameRegex.test(item.name)) {
					errors.push(errPrefix + ' name should only contain letters, numbers and these special characters: &",.!\'-');
				}

				// Description
				if (item.description && item.description.length > 0 && !this.remarksRegex.test(item.description)) {
					errors.push(errPrefix + ' description should only contain letters, numbers and these special characters: &\'"-,/():;!*[]');
				}

				// Company Type
				if (!item.type || item.type.length === 0) {
					errors.push(errPrefix + ' has no type. This is required.');
				} else if (item.type && item.type.length !== 0 && !companyTypes.includes(item.type)) {
					let errMsg = errPrefix + ' type, "' + item.type + '", is invalid. Valid types are: ';
					errMsg += companyTypes.join() + '.';
					errors.push(errMsg);
				}

				// Industry
				if (item.industry.length === 0) {
					errors.push(errPrefix + ' has no industry. This is required');
				}

				// Parent Company
				if (!this.isSuperAdmin && !item.hasParentCompany) {
					errors.push(errPrefix + ' has no parent company. This is required for company view.');
				} else if (item.hasParentCompany && item.parentCompany.length === 0) {
					errors.push(errPrefix + ' has no parent company. This is required.');
				} else if (!this.isSuperAdmin && item.parentCompany !== this.loggedUserCompany.name) {
					errors.push(errPrefix + ' parent company is not allowed. The only allowed parent company is ' + this.loggedUserCompany.name + '.');
				}

				// Address
				if (!item.address || item.address === null) {
					errors.push(errPrefix + ' has no address. This is required.');
				} else if (!this.addressRegex.test(item.address)) {
					errors.push(errPrefix + ' address should only contain letters, numbers and these special characters: (),.#\'-');
				}

				// Latitude
				let latitude = item.geoaddress.latitude;
				if (!latitude || latitude.length === 0) {
					errors.push(errPrefix + ' has no latitude. This is required.');
				} else if (latitude && !ImportUtil.isValidNumber(latitude)) {
					errors.push(errPrefix + ' latitude, "' + longitude + '", should be a number.');
				} else if (latitude < -90 || latitude > 90) {
					errors.push(errPrefix + ' latitude must not be less than -90 or greater than 90.');
				}

				// Longitude
				let longitude = item.geoaddress.longitude;
				if (!longitude || longitude.length === 0) {
					errors.push(errPrefix + ' has no longitude. This is required.');
				} else if (longitude && !ImportUtil.isValidNumber(longitude)) {
					errors.push(errPrefix + ' longitude, "' + longitude + '", should be a number.');
				} else if (longitude < -180 || longitude > 180) {
					errors.push(errPrefix + ' longitude must not be less than -180 or greater than 180.');
				}

				// Contact Person
				if (item.contactPerson && item.contactPerson.length > 0 && !this.fullNameRegex.test(item.contactPerson)) {
					errors.push(errPrefix + ' contact person should only contain letters and dots.');
				}

				// Email Address
				if (item.emailAddress && item.emailAddress.length > 0 && !this.emailRegex.test(item.emailAddress)) {
					errors.push(errPrefix + ' email address format is invalid');
				}

				// Contact No
				item.contactNo = String(item.contactNo);
				if (item.contactNo && item.contactNo.length > 0 && !this.contactNoRegex.test(item.contactNo)) {
					errors.push(errPrefix + ' contact no must follow this format: +639xxxxxxxxx.');
				}

				// Brand
				if (!item.brand || item.brand === null) {
					errors.push(errPrefix + ' has no brand. This is required.');
				} else if (item.brand && !this.brandRegex.test(item.brand)) {
					errors.push(errPrefix + ' brand should only contain letters and numbers without white spaces.');
				}

				// Branch Code
				if (item.branchCode && item.branchCode.length > 0 && !this.branchCodeRegex.test(item.branchCode)) {
					errors.push(errPrefix + ' branch code should only contain letters, numbers and these special characters: &\'-,./')
				}
			});

			if (errors.length > 0) {
				isValid = false;

				this.hasError = true;
				this.importErrors = errors;
				this.importResultLog = 'The imported form has error(s).';
				this.importDone = true;
				this.importOngoing = false;
			}

			return isValid;
		},
		async saveOnDatabase(json) {
			try {
				// show loading indicator
				this.isLoading = true;

				this.importOngoing = true;
				this.importDone = false;

				let param = {
					currUserId: this.currUserId,
					currTimeStamp: DateUtil.getCurrentTimestamp(),
					companies: json.companies,
				};

				let { data } = await dataImporterApi.importCompanies(param);

				this.hasError = !data.isSuccess;
				this.importErrors = data.errors;
				this.importResultLog = data.message;
				this.importDone = true;
				this.importOngoing = false;

				// update store
				let companiesObj = data.companies;
				this.$store.dispatch('updateAllCompanies', companiesObj);
			} catch (error) {
				this.hasError = true;
				this.importResultLog = error;
				this.importDone = true;
				this.importOngoing = false;
			}

			// hide loading indicator
			this.isLoading = false;
		}
	},
};
</script>
